
















import { Component, Mixins, Watch } from 'vue-property-decorator';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { ListingStatus } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';

import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
  },
})
export default class Sold extends Mixins(View) {
  private listingFilter: ListingFilter | null = null;

  @Watch('$route')
  updateRoute() {
    const filter = new ListingFilter({
      Statuses: [
        ListingStatus.Sold,
      ],
      PageSize: 9,
      OrderByStatements: [
        ListingOrderBy.SoldDateDesc,
      ],
    });

    const params = this.$route.query;

    try {
      if (params.suburbs && typeof params.suburbs === 'string') filter.Suburbs = params.suburbs.split('|').filter((s) => s && s.length);

      if (params.rentalPriceMin && typeof params.rentalPriceMin === 'string') filter.MinPrice = params.rentalPriceMin;
      if (params.rentalPriceMax && typeof params.rentalPriceMax === 'string') filter.MaxPrice = params.rentalPriceMax;

      if (params.beds && typeof params.beds === 'string') filter.MinBedrooms = params.beds;
      if (params.baths && typeof params.baths === 'string') filter.MinBathrooms = params.baths;

      if (params.areaMin && typeof params.areaMin === 'string') filter.MinLandArea = params.areaMin;
      if (params.areaMax && typeof params.areaMax === 'string') filter.MaxLandArea = params.areaMax;
    } catch (ex) {
      console.error(ex);
    }

    this.listingFilter = filter;
  }

  mounted() {
    this.updateRoute();
  }
}
